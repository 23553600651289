@import '@aws-amplify/ui-react/styles.css';

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");

.amplify-tabs {
  display: none;
}

:root {
  --amplify-fonts-default-variable: "Open Sans";
  --amplify-fonts-default-static: "Open Sans";
  --amplify-colors-brand-primary-10: #ebeced;
  --amplify-colors-brand-primary-20: #637e9b;
  --amplify-colors-brand-primary-40: #496988;
  --amplify-colors-brand-primary-60: #3c5b78;
  --amplify-colors-brand-primary-80: #2d4962;
  --amplify-colors-brand-primary-90: #1f374c;
  --amplify-colors-brand-primary-100: #0d2335;
  --amplify-colors-brand-secondary-10: #988169;
  --amplify-colors-brand-secondary-20: #80674b;
  --amplify-colors-brand-secondary-40: #694e2e;
  --amplify-colors-brand-secondary-60: #5f4528;
  --amplify-colors-brand-secondary-80: #503920;
  --amplify-colors-brand-secondary-90: #432c18;
  --amplify-colors-brand-secondary-100: #351f0d;
  --amplify-colors-radii-small: 0;
  --amplify-colors-radii-medium: 4px;
  --amplify-colors-radii-large: 4px;
  --amplify-colors-border-primary: #374b5f;
  --amplify-colors-border-secondary: #374b5f;
  --amplify-colors-border-tertiary: #374b5f;
  --amplify-colors-background-secondary: #374b5f;
  --amplify-components-tabs-item-border-color: #818b94;
  --amplify-components-button-primary-background-color: #0d2335;
  --amplify-components-button-primary-color: #ffffff;
  --amplify-components-heading-3-font-size: 1.5rem;
  
  --amplify-components-authenticator-router-background-color: none;
  --amplify-components-authenticator-router-box-shadow: none;
  --amplify-components-authenticator-router-border-color: none;
  --amplify-components-authenticator-router-border-width: none;
  --amplify-components-authenticator-router-border-style: none;
}